var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor"},[_c('div',{staticClass:"clearfix editor__content"},[_c('div',{staticClass:"my-3 text-center"},[_c('h1',[_vm._v(_vm._s(_vm.sheet.name))])]),(_vm.sheet.data && _vm.sheet.data.definition)?_c('p',{staticClass:"mb-3 text-center",domProps:{"innerHTML":_vm._s(_vm.sheet.data.definition)}}):_vm._e(),(_vm.sheet.data && _vm.sheet.data.pilote_success_keys)?_c('blockquote',{staticClass:"success mb-3"},[_c('div',{staticClass:"px-3 py-2"},[_c('h2',{staticClass:"text-success"},[_c('i',{staticClass:"icon-stack-check mr-1"}),_vm._v(" "+_vm._s(_vm.$t('modules.quest.sheet.success_keys')))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.sheet.data.pilote_success_keys)}})])]):_vm._e(),(_vm.sheet.data && _vm.sheet.data.pilote_goals)?_c('blockquote',{staticClass:"info mb-3"},[_c('div',{staticClass:"px-3 py-2"},[_c('h2',[_c('i',{staticClass:"icon-target mr-1"}),_vm._v(" "+_vm._s(_vm.$t('modules.quest.sheet.goals')))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.sheet.data.pilote_goals)}})])]):_vm._e(),(_vm.sheet.data && _vm.sheet.data.pilote_embed_video)?_c('div',{staticClass:"pilote-embed-video mb-3"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.sheet.data.pilote_embed_video)}})]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.sheet.pilote_description)}})]),(
      _vm.sheet.data.actions_related &&
      _vm.sheet.data.actions_related.length > 0 &&
      _vm.currentWorld.modules.actionsboard
    )?_c('div',{staticClass:"bg-light rounded px-3 pt-3 pb-2 mb-3"},[_c('el-card-title',{staticClass:"font-weight-semibold",attrs:{"title":_vm.$t('modules.wiki.content.actions_related')}}),_c('div',{staticClass:"mt-2"},_vm._l((_vm.sheet.data.actions_related),function(action){return _c('div',{},[_c('action-create-box',{attrs:{"name":action}})],1)}),0)],1):_vm._e(),(
      _vm.templatesRelated != null &&
      _vm.templatesRelated.length > 0 &&
      _vm.currentWorld.modules.templates
    )?_c('div',{staticClass:"bg-light rounded px-3 pt-3 pb-1 mb-3"},[_c('el-card-title',{staticClass:"font-weight-semibold",attrs:{"title":_vm.$t('modules.wiki.content.templates_related')}}),_c('div',{staticClass:"row mt-2"},_vm._l((_vm.templatesRelated),function(template){return _c('div',{key:("template-" + (template.tpl_entity.id)),staticClass:"col-lg-6 col-md-12 pb-3"},[_c('templates-template-card',{attrs:{"template":template.tpl_entity}})],1)}),0)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }